
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  nextTick,
  computed,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import PaginationUi from "@/components/ABilling/PaginationXS.vue";
import { useRouter } from "vue-router";
import {
  addIcd10,
  searchIcd10s,
  updateIcd10,
  searchByCodeIcd10s,
} from "@/api/code-master-icd10.api";
import { helpers, required, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useFilters from "@/modules/common/useFilters";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getPanel } from "@/api/encounter.api";
import debounce from "lodash.debounce";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  isDirty: boolean;
  facility?: any;
  items: any[];
  displayInactive: boolean;
  selectedItem: any;
  previousSelectedItem: any;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  keyword?: string;
  pagination: Pagination;
  dict: {
    facilities;
  };
}
export default defineComponent({
  name: "Icd10",
  props: ["facility"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  components: { PaginationUi, DateFloatComponent },
  setup(props) {
    let organizationId = ref<string | null>("");

    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "Code",
        key: "code",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Description",
        key: "description",
        isFilter: true,
        keyword: null,
      },
      {
        name: "In Use",
        key: "inUse",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Active",
        key: "isActive",
        isFilter: true,
        keyword: null,
      },
      // {
      //   name: "Expired",
      //   key: "expired",
      //   isFilter: true,
      //   keyword: null,
      //   IsAdvancedOrderBy: true,
      // },
    ];

    const router = useRouter();
    let data = reactive<Data>({
      isDirty: false,
      items: [],
      displayInactive: false,
      selectedItem: undefined,
      previousSelectedItem: undefined,
      keyword: "",
      orderBy: "code",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      dict: {
        facilities: [],
      },
    });

    watch(
      () => props.facility,
      async (currentValue, oldValue) => {
        data.selectedItem = null;
        data.previousSelectedItem = null;
        data.isDirty = false;
        await selectFacility();
      }
    );

    watch(
      () => data.selectedItem,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousSelectedItem &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousSelectedItem)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    const Icd10Rules = {
      selectedItem: {
        code: {
          required: helpers.withMessage("Required", required),
          length: helpers.withMessage(
            "The maximum length allowed is 10",
            maxLength(10)
          ),
        },
        description: {
          required: helpers.withMessage("Required", required),
          length: helpers.withMessage(
            "The maximum length allowed is 275",
            maxLength(275)
          ),
        },
        shortDescription: {
          length: helpers.withMessage(
            "The maximum length allowed is 150",
            maxLength(150)
          ),
        },
        effectiveDate: { required: helpers.withMessage("Required", required) },
      },
    };

    let v$ = useVuelidate(Icd10Rules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;

      await getAll();
    });

    async function selectFacility() {
      data.keyword = undefined;
      await getAll();
    }

    async function debounceGetAll() {
        await debounce(getAll, useFilters().debounceMs)();
    }

    async function getAll() {
      if (!props.facility) {
        return;
      }

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        search: data.keyword,
        facilityId: props.facility,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        displayInactive: data.displayInactive,
      };

      const res = await searchIcd10s(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      v$.value.$reset();
    }

    function selectItem(item) {
      data.selectedItem = JSON.parse(JSON.stringify(item));
      data.previousSelectedItem = JSON.parse(JSON.stringify(item));
    }

    async function addItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        var icdCodes = await searchByCodeIcd10s({
          facilityId: props.facility,
          code: data.selectedItem.code,
        });

        if (icdCodes.length != 0) {
          Swal.fire({
            text: "The code you entered is already in the list.",
            buttonsStyling: false,
            confirmButtonText: "Go Back",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn ab-btn-uniform",
            },
          });
        } else {
          data.selectedItem.facilityId = props.facility;
          data.selectedItem.id = await addIcd10(data.selectedItem);
          data.isDirty = false;
          await getAll();
          data.selectedItem = null;
          data.previousSelectedItem = null;
        }
      }
    }

    async function updateItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        var icdCodes = await searchByCodeIcd10s({
          facilityId: props.facility,
          code: data.selectedItem.code,
        });

        if (icdCodes.length != 0 && data.selectedItem.id != icdCodes[0].id) {
          Swal.fire({
            text: "The code you entered is already in the list.",
            buttonsStyling: false,
            confirmButtonText: "Go Back",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn ab-btn-uniform",
            },
          });
        } else {
          await updateIcd10(data.selectedItem);
          const selectedItemInListIndex = data.items.findIndex((item: any) => {
            return item.id == data.selectedItem.id;
          });
          data.items[selectedItemInListIndex] = data.selectedItem;
          await getAll();
          data.selectedItem = null;
          data.previousSelectedItem = null;
          data.isDirty = false;
        }
      }
    }

    async function addMode() {
      v$.value.$reset();
      data.selectedItem = {
        isActive: true,
        effectiveDate: new Date(),
      };
      await nextTick();
      data.previousSelectedItem = JSON.parse(JSON.stringify(data.selectedItem));
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;

      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function cancel() {
      if (data.isDirty) {
        let text = "Are you sure you want to leave without saving changes?";
        Swal.fire({
          title: text,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            data.selectedItem = null;
            data.previousSelectedItem = null;
            data.isDirty = false;
          }
        });
      } else {
        data.selectedItem = null;
        data.previousSelectedItem = null;
      }
    }

    const nowDate = computed(() => {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    });

    const minTermDate = computed(() => {
      if (new Date(data.selectedItem.effectiveDate) > nowDate.value)
        return data.selectedItem.effectiveDate;
      return nowDate.value;
    });

    return {
      pageChanged,
      pageSizeChanged,
      organizationId,
      selectItem,
      updateItem,
      addItem,
      addMode,
      selectFilter,
      getSortInfo,
      orderList,
      debounceGetAll,
      getAll,
      selectFacility,
      cancel,
      minTermDate,
      props,
      v$,
      data,
    };
  },
});
